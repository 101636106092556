import {useState} from 'react';
import * as GeoDataService from "../api/GeoDataService";

export default function GeoJsonIoLink({scenario}) {
  const [isLoadingLink, setIsLoadingLink] = useState(false);
  const [fileLink, setFileLink] = useState(null);

  console.log(scenario);

  function handleClick() {
    setIsLoadingLink(true);

    GeoDataService.GetGeoJsonIoLink(scenario).then(result => {
      console.log(result);
      setFileLink(result["data"]);
      setIsLoadingLink(false);
    }).catch(error => {
      console.error(error);
      setIsLoadingLink(false);
    });
  }


  let buttonTxt = "Create Link"
  switch (scenario) {
    case "a":
      buttonTxt = "Scenario: Low Risk Warnings";
      break;
    case "b":
      buttonTxt = "Scenario: Mixed Risk Warnings";
      break;
    case "c":
      buttonTxt = "Scenario: High Risk Warnings";
      break;
    default:
      buttonTxt = "Scenario: No Warnings";
  }


  let button;
  if (fileLink == null) {
    button = <button onClick={handleClick}>
      Create {buttonTxt}
    </button>;
  } else {
    let geoJsonIoLink = GenerateGeoJsonLink(fileLink);
    button = <>
      <a href={geoJsonIoLink} target="_blank" style={{padding: "0 16px"}}>
        {geoJsonIoLink}
      </a>
      <button onClick={handleClick}>
        Refresh {buttonTxt}
      </button>
    </>;
  }

  let body
  if (isLoadingLink) {
    body = <p>Loading...</p>
  } else {
    body = button;
  }

  return (

    <>
      {body}
    </>
  );

  function GenerateGeoJsonLink(fileLink) {
    // https://geojson.io/#data=data:text/x-url,https://Fquiz.exdimum.ameno.de/test/geo.json&map=9.7/4.4465/23.065
    let link = "https://geojson.io/#data=data:text/x-url," + encodeURIComponent(fileLink);

    return link;
  }
}