import logo from './logo.svg';
import './App.css';
import GeoJsonIoLink from "./components/GeoJsonIoLink";
import GeoJsonEditForm from "./components/GeoJsonEditForm";

function App() {
  return (
    <div className="App">
      <div style={{margin: "22px auto"}}>
        <GeoJsonIoLink scenario="default"/>
      </div>
      <div style={{margin: "22px auto"}}>
        <GeoJsonIoLink scenario="a"/>
      </div>
      <div style={{margin: "22px auto"}}>
        <GeoJsonIoLink scenario="b"/>
      </div>
      <div style={{margin: "22px auto"}}>
        <GeoJsonIoLink scenario="c"/>
      </div>

      <hr/>
      <div style={{margin: "22px auto"}}>
        <GeoJsonEditForm/>
      </div>
    </div>
  );
}

export default App;
