import axios from "axios";

// const BASE_URL = "https://localhost:7158/"
const BASE_URL = "https://geodata.exdimum.ameno.de/"
const GEOMETRY_PATH = "api/Geometry/"

export async function GetGeoJsonIoLink(scenario = "default") {
  let target = `GeoJsonIoLink?scenario=${scenario}`;

  return axios.get(BASE_URL + GEOMETRY_PATH + target);
}

export async function PostGeoJson(geoJson) {
  let target = "ApplyGeoJson";

// Loop through each feature
  geoJson = JSON.parse(geoJson);
  geoJson.features.forEach(feature => {
    // Check if the feature has properties
    if (feature.properties) {
      // Loop through each property and convert to string
      for (let prop in feature.properties) {
        feature.properties[prop] = String(feature.properties[prop]);
      }
    }
  });

  geoJson = JSON.stringify(geoJson, null, 2);

  let customConfig = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  console.log("GeoJson", geoJson);

  return axios.post(BASE_URL + GEOMETRY_PATH + target, geoJson, customConfig);
}