import {useState} from 'react';
import * as GeoDataService from "../api/GeoDataService";

export default function GeoJsonEditForm() {
  const [isUploading, setIsUploading] = useState(false);
  const [geoJson, setGeoJson] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setsuccessMsg] = useState("");

  function handleClick() {
    setIsUploading(true);
    setsuccessMsg("");
    setErrorMsg("");

    GeoDataService.PostGeoJson(geoJson).then(result => {
      setsuccessMsg("Features Updated");
      setIsUploading(false);
    }).catch(error => {
      console.error(error);
      // setErrorMsg(error.value);
      setErrorMsg(error.message);
      setIsUploading(false);
    })
  }

  function handleValueChanged(event) {
    setGeoJson(event.target.value);
  }

  let button =
    <button onClick={handleClick} style={{margin: "22px auto"}}>
      Save To Database
    </button>;

  return (
    <>
      <div>
        <textarea
          cols={160}
          rows={35}
          readOnly={isUploading}
          onChange={handleValueChanged}
        ></textarea>
      </div>
      {successMsg ? <div style={{color: "#10DD10"}}>{successMsg}</div> : null}
      {errorMsg ? <div style={{color: "#DD1010"}}>{errorMsg}</div> : null}
      <div>
        {isUploading ? "Uploading..." : button}
      </div>
    </>

  );
}